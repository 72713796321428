import React from 'react'
import {navigateTo} from 'gatsby'
import { Formik, Form, Field, ErrorMessage } from 'formik'
/* import Layout from "../components/layout" */
import SEO from "../components/seo"

import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { Debug } from '../components/debug'

import DatePickerWithFormik from "../components/date-picker";

/* STYLES */

const Dateflessibili = styled(Field)`
	margin: 0 auto;
	margin-top: 22px;
`
const FormCSS = css`
	label>input, select, button, textarea {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		vertical-align: middle;
	}
	textarea {
		width: 300px;
		height: 100px;
	}
	button {
		margin-left: 6rem;
	}
`

/* VALORI INIZIALI */

const formInitialValues = {
	email: '',
	name: '',
	message: '',
	datePickerWithFormik: {
		myStart: null,
		myEnd: null
	},
	Adulti: '1',
	Bambini: '0',
	Trattamento: '',
	Date_Flessibili: false,
	Privacy_Policy: false,
	Newsletter: false
};

function serialize(obj, prefix) {
	var str = [],
		p;
	for (p in obj) {
		if (obj.hasOwnProperty(p)) {
			var k = prefix ? prefix + "[" + p + "]" : p,
				v = obj[p];
			str.push((v !== null && typeof v === "object") ?
				serialize(v, k) :
				encodeURIComponent(k) + "=" + encodeURIComponent(v));
		}
	}
	return str.join("&");
}

const Formdicontatto = () => (
<>
{/* 	<Layout> */}
		<SEO title="Form con Formik" />
		<main className="pagina-form-contatto">
		<h1>Chiedi un preventivo</h1>
		<Formik
				initialValues={formInitialValues}
			validate={values => {
				let errors = {};
				if (!values.email) {
					errors.email = 'Required';
				} else if (
					!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
				) {
					errors.email = 'Invalid email address';
				}
				return errors;
			}}
			onSubmit={(values) => {
				fetch("/", {
					method: "POST",
					headers: { "Content-Type": "application/x-www-form-urlencoded" },
					body: serialize({
						"form-name": "formik",
						...values
					})
				})
					.then(() => navigateTo("/grazie"))
					.catch(error => alert(error))
			}}
		>
			{({ isSubmitting }) => (
					<Form name="formik" data-netlify="true" action="/grazie" css={FormCSS}>
						<input type="hidden" name="form-name" value="formik" />
					<label>
							<Field type="text" name="name" placeholder="Nome Cognome" />
							<ErrorMessage name="name" component="div" />
					</label>
					<br />
					<label>
					<Field type="email" name="email" placeholder="Email *necessaria" required />
					<ErrorMessage name="email" component="div" />
					</label>
					<br />
						<Field name="Adulti" component="select" placeholder="Adulti">
							<option value="1 Adulto">1 Adulto</option>
							<option value="2 Adulti">2 Adulti</option>
							<option value="3 Adulti">3 Adulti</option>
						</Field>
						&nbsp;
						<Field name="Bambini" component="select" placeholder="Bambini">
							<option value="0 Bambini">0 Bambini</option>
							<option value="1 Bambino">1 Bambino</option>
							<option value="2 Bambini">2 Bambini</option>
							<option value="3 Bambini">3 Bambini</option>
						</Field>
						<br />
						<Field name="Trattamento" component="select" placeholder="Scegli il trattamento">
							<option value="All Inclusive">All Inclusive</option>
							<option value="Pensione Completa">Pensione Completa</option>
							<option value="Mezza Pensione">Mezza Pensione</option>
							<option value="Bed and Breakfast">Bed and Breakfast</option>
							<option value="Solo Dormire">Solo Dormire</option>
						</Field>
						<br />

						<label>Giorno di arrivo e partenza<br />
						<Field
							component={DatePickerWithFormik}
							name="DatePickerWithFormik"
							required
						/>
						</label>
						<br />
					{/*<label>Giorno di arrivo e partenza<br />
					<Field type="date" name="start" required />
					<ErrorMessage name="start" />
					&nbsp;
					<Field type="date" name="end" required />
					<ErrorMessage name="end" />
					</label>
					*/}			
					<label>
						Richieste particolari: <br />
						<Field type="text" component="textarea" placeholder="Scrivi qui le tue richieste" name="message" />
						<ErrorMessage name="message" />
					</label>
					<br />
					<label>
						Date flessibili: &nbsp;
						<Dateflessibili type="checkbox" name="Date_Flessibili" />
						<ErrorMessage name="Date_Flessibili" />
					</label>
					<br />
					<label>
						Accettazione privacy policy: &nbsp;
						<Field type="checkbox" name="Privacy_Policy" required />
						<ErrorMessage name="Privacy_Policy" />
					</label>
					<br />
					<label>
						Vuoi iscriverti alla newsletter?: &nbsp;
						<Field type="checkbox" name="Newsletter" />
						<ErrorMessage name="Newsletter" />
					</label>
					<br />

					<button type="submit" disabled={isSubmitting}>
						Invia
          </button>

					 <Debug />

					</Form>
			)}
		</Formik>
		
		</main>
{/* 	</Layout> */}
</>
)

export default Formdicontatto