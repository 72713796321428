import React, { useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

const DatePickerWithFormik = ({
	startDateId,
	endDateId,
	form: { setFieldValue, setFieldTouched, values },
	field,
	...props
}) => {
	console.log(values);
	// const [startDate, setStartDate] = useState(null);
	// const [endDate, setEndDate] = useState(null);
	const [focusedInput, setFocusedInput] = useState(null);
	// const handleDatesChange = ({ startDate, endDate }) => {
	//   // setStartDate(startDate);
	//   // setEndDate(endDate);
	//   setFieldValue("startDate", startDate);
	//   setFieldValue("endDate", endDate);
	// };
	const keys = Object.keys(values.datePickerWithFormik);
	console.log(keys);
	return (
			<DateRangePicker
				startDate={values.datePickerWithFormik[keys[0]]}
				startDateId="Arrivo"
				endDate={values.datePickerWithFormik[keys[1]]}
				endDateId="Partenza"
				onDatesChange={({ startDate, endDate }) => {
					setFieldValue(`datePickerWithFormik.${keys[0]}`, startDate);
					setFieldValue(`datePickerWithFormik.${keys[1]}`, endDate);
				}}
				focusedInput={focusedInput}
				onFocusChange={focusedInput => setFocusedInput(focusedInput)}
			/>
	);
};

export default DatePickerWithFormik;
